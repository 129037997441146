import React from 'react';
import { lighten } from '@mui/material/styles';
import LandingSection from './../LandingSection';
import { Filter1, Filter2, Filter3 } from '@mui/icons-material';
import Features from './../Features';
import fiberpayLogo from '../../../images/fiberpay_logo.svg';

const products = [
  {
    name: 'Instalacja i konfiguracja aplikacji',
    description: (
      <>
        Użytkownik instaluje aplikację autoryzującą na swoim telefonie oraz
        podłącza urządzenie do swojego konta w Twojej Platformie podając kod lub
        skanując kod QR
      </>
    ),
    icon: <Filter1 sx={{ fontSize: { xs: 40, md: 60 } }} />,
    url: '/',
  },
  {
    name: 'Przekierowanie do FiberID',
    description: (
      <>
        Twoja Platforma wysyła za pomocą API żądanie autoryzacji, a aplikacja
        wyświetla powiadomienia o próbie dostępu lub wykonania zabezpieczonych
        operacji w telefonie użytkownika
      </>
    ),
    icon: <Filter2 sx={{ fontSize: { xs: 40, md: 60 } }} />,
    url: '/products/fiberid/',
  },
  {
    name: 'Ankieta użytkownika',
    description: (
      <>
        Użytkownik zatwierdza lub odrzuca operację, a informacja trafia do
        Twojej Platformy, co pozwala Ci kontynuować wykonanie lub podjąć środki
        bezpieczeństwa w przypadku braku autoryzacji
      </>
    ),
    icon: <Filter3 sx={{ fontSize: { xs: 40, md: 60 } }} />,
    url: '/products/fiberaml/',
  },
];

const Products = () => (
  <LandingSection
    columnText={false}
    sx={{
      background: (
        theme
      ) => `url(${fiberpayLogo}) no-repeat center, linear-gradient(
    90deg,
    ${lighten(theme.palette.primary.lighter, 0.2)} 0%,
    ${theme.palette.primary.darker} 100%)`,
      marginLeft: 0,
      marginRight: 0,
    }}
    id='produkty'
    subtitle='Jak to działa'
    title='Proces ochrony Twoich użytkowników w 3 prostych krokach'
    // colLeftText="Sprawnie zbuduj Twoje procesy onboardingu klientów, weryfikacji tożsamości, oceny
    //       ryzyka, autoryzacji transakcji, wielokanałowego przyjmowania płatności, naliczania prowizji od transakcji oraz
    //       dystrybucji wypłat - wszystko na jednej, łatwej w obsłudze platformie technologicznej."
    // colRightText="Obniż koszty budowy i utrzymania aplikacji oraz zapewnij bezpieczeństwo i zgodność z
    //       obowiązującymi regulacjami - bez rozwiązywania problemów ze współpracą rozwiązań od wielu różnych
    //       dostawców."
    actionButtonText='Sprawdź już teraz'
    actionButtonUrl={
      'https://play.google.com/store/apps/details?id=fiber.token&gl=PL'
    }
    isActionUrlExternal={true}
  >
    <Features
      products={products}
      largeNum={4}
      buttonOn={false}
      avatarOff={false}
    />
  </LandingSection>
);

export default Products;
