import React from 'react';
import Footer from '../../components/common/Footer';
import Hero from '../../components/landing/fibertoken/Hero';
import Landing from '../../components/landing/fibertoken/Landing';
import Pricing from '../../components/landing/fibertoken/Pricing';
import Products from '../../components/landing/fibertoken/Products';
import ProductsVerification from '../../components/landing/fibertoken/ProductsVerification';
import Seo from '../../seo';

const IndexPage = () => {
  return (
    <>
      <Seo
        title='Silne uwierzytelnienie 2FA z aplikacją FiberToken | Fiberpay'
        description='Zwiększ bezpieczeństwo swoich użytkowników dzięki autoryzacji 
        operacji w dedykowanej aplikacji mobilnej.'
        metaImage='meta_image_fibertoken.png'
        currentUrl='https://fiberpay.pl/produkty/silne-uwierzytelnienie-2FA-fibertoken'
      />
      <Hero />
      <Landing />
      <Products />
      <ProductsVerification />
      <Pricing />
      <Footer />
    </>
  );
};

export default IndexPage;
