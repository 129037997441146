import React from 'react';
import { lighten } from '@mui/material/styles';
import LandingSection from './../LandingSection';
import { Favorite, Lock, MonetizationOn } from '@mui/icons-material';
import Features from './../Features';
import fiberpayLogo from '../../../images/fiberpay_logo.svg';
const products = [
  {
    name: 'Bezpieczeństwo',
    description: (
      <>
        Autoryzacja krytycznych operacji w dedykowanej aplikacji zwiększa bezpieczeństwa
        użytkowników i ogranicza Twoje ryzyko reputacyjne
      </>
    ),
    icon: <Lock sx={{ fontSize: { xs: 40, md: 60 } }} />,
    url: '/',
  },
  {
    name: 'Niższe koszty',
    description: (
      <>
        FiberToken jest gotowym, wypróbowanym rozwiązaniem i jest tańszy niż wysyłanie kodów SMSami
        lub tworzenie własnej aplikacji MFA
      </>
    ),
    icon: <MonetizationOn sx={{ fontSize: { xs: 40, md: 60 } }} />,
    url: '/products/fiberid/',
  },
  {
    name: 'Wygoda',
    description: (
      <>
        Potwierdzenie operacji jest wygodniejsze i prostsze niż przepisywanie kodów z SMSów, maili
        lub aplikacji obsługujących szereg innych serwisów
      </>
    ),
    icon: <Favorite sx={{ fontSize: { xs: 40, md: 60 } }} />,
    url: '/products/fiberaml/',
  },
];

const ProductsVerification = () => (
  <LandingSection
    columnText={false}
    sx={{
      marginTop: (theme) => theme.spacing(12),
      position: 'relative',
      background: (
        theme
      ) => `url(${fiberpayLogo}) no-repeat center, linear-gradient(
    90deg,
    ${lighten(theme.palette.primary.lighter, 0.2)} 0%,
    ${theme.palette.primary.darker} 100%)`,
      marginLeft: 0,
      marginRight: 0,
    }}
    id='produkty'
    subtitle='Korzyści'
    title='FiberToken jest bezpieczniejszy, tańszy i wygodniejszy niż alternatywy'
    actionButtonText='Sprawdź już teraz'
    actionButtonUrl={
      'https://play.google.com/store/apps/details?id=fiber.token&gl=PL'
    }
    isActionUrlExternal={true}
  >
    <Features
      products={products}
      largeNum={4}
      buttonOn={false}
      avatarOff={true}
    />
  </LandingSection>
);

export default ProductsVerification;
