import { Box } from '@mui/material';
import React from 'react';
import EndInfoBanner from '../EndInfoBanner';

const textUp = 'Wygodne i bezpieczne rozwiązanie MFA';

const textDown = (
  <>
    ...<strong>strzegące</strong> dostępu do kont użytkowników na&nbsp;
    <strong>Twojej</strong> Platformie
  </>
);

const textButton = <> Pobierz&nbsp;aplikacje </>;

const pageLink =
  'https://play.google.com/store/apps/details?id=fiber.token&gl=PL';

const Pricing = () => (
  <>
    <Box
      sx={{
        height: { xs: '32px', sm: '96px' },
        width: '100%',
        zIndex: -1,
        background: '#EBF2FA',
      }}
    />
    <EndInfoBanner
      textGridhWidth={12}
      textUp={textUp}
      textUpFoWe={400}
      textDown={textDown}
      textDoFoWe={500}
      textButton={textButton}
      pageLink={pageLink}
    />
  </>
);

export default Pricing;
