import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import Navbar from '../../navbar/navbar';
import { CallToActionButton } from '../../common/Button';
import BaseContainer from '../../common/BaseContainer';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import LandingBackgroundFiberToken from '../../images/Landing-fibertoken';

const subtitles = [
  <>
    <strong>Autoryzacja</strong> operacji / <strong>MFA</strong> w dedykowanej
    aplikacji mobilnej
  </>,
];

const HeroBr = styled('br')(({ theme }) => ({
  [theme.breakpoints.down('800')]: {
    display: 'none',
  },
}));

const BoxWrapper = styled('div')(() => ({
  color: 'white',
  position: 'relative',
  '&::before': {
    content: '" "',
    position: 'absolute',
    top: '0',
    left: '0',
    height: '100%',
    width: '100%',
    background: 'linear-gradient(135deg, #3180de, #1e5abb, #69b3e6)',
    opacity: 0.75,
  },
}));

const Hero = () => (
  <LandingBackgroundFiberToken>
    <BoxWrapper>
      <BaseContainer>
        <Navbar
          loginLink={
            'https://play.google.com/store/apps/details?id=fiber.token&gl=PL'
          }
        />
        <Typography
          variant='h2'
          component='h1'
          sx={{
            fontSize: {
              xs: '2.1rem',
              sm: '2.6rem',
              md: '3.7rem',
              lg: '4.3rem',
              xl: '5.4rem',
            },

            fontWeight: 'bold',
            mt: 12,
            zIndex: 100,
            position: 'relative',
          }}
          color='white'
        >
          Zwiększ bezpieczeństwo <HeroBr /> swoich użytkowników
        </Typography>
        <Grid container sx={{ my: 4 }} spacing={2}>
          {subtitles.map((subtitle, idx) => (
            <Grid item xs={12} key={idx}>
              <Typography
                variant='subtitle1'
                sx={{
                  color: 'white',
                  fontWeight: 'light',
                  position: 'relative',
                }}
              >
                {subtitle}
              </Typography>
            </Grid>
          ))}
        </Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center', lg: 'flex-start' },
          }}
        >
          <a
            href='https://play.google.com/store/apps/details?id=fiber.token&gl=PL'
            style={{ textDecoration: 'none' }}
          >
            <CallToActionButton
              endIcon={<ArrowForwardOutlinedIcon />}
              // sx={{ mt: 12, mb: { xs: 24, lg: 24 } }}
              sx={{
                mt: { xs: 12 },
                mb: { xs: 24 },
                fontSize: { xs: '14px', sm: '16px', md: '18px', lg: '20px' },
                paddingY: { xs: '10px', sm: '12px', md: '14px', lg: '16px' },
                paddingX: { xs: '15px', sm: '32px', md: '48px', lg: '64px' },
              }}
            >
              Pobierz aplikacje
            </CallToActionButton>
          </a>
        </Box>
      </BaseContainer>
    </BoxWrapper>
  </LandingBackgroundFiberToken>
);

export default Hero;
