import React from 'react';
import TextTransform from '../TextTransform';

const TEXTS = [
  'Chcesz chronić użytkowników Twoich aplikacji przez włamaniami na ich konta?',
  'Jesteś zobowiązany do stosowania uwierzytelniania wieloczynnikowego (MFA)?',
  'Chcesz zapewnić użytkownikom wygodną, szybką i bezpieczną metodę potwierdzania ich zleceń?',
];

const Landing = () => {
  return <TextTransform text={TEXTS} backColor={'#EBF2FA'} />;
};

export default Landing;
